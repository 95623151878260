<template>
  <div id="invoice-list" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <movements :data="sidebarData" :isSidebarActive="showMovements"
               @closeSidebar="showMovements= false"/>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <div>
        <h6 class="mt-2 mb-4">Rango de fechas</h6>
        <!--Clients-->
        <div class="mb-4">
          <h6 class="text-sm opacity-75 mb-2">Selecciona cliente</h6>
          <v-select :options="clients" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="selectedClient" class="mb-4 md:mb-0" name="client" id="client"/>
        </div>
        <!--End-->
        <!--Dates-->
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Desde</label>
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Hasta</label>
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
        <!--End-->
        <!--Type and product-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="text-sm opacity-75 mb-2">Tipo de búsqueda*</h6>
              <v-select :options="types" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="selectedType" class="mb-4 md:mb-0" name="type" id="type"/>
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input label="Producto"
                        v-model="selectedSKU"
                        class="w-full" name="sku"/>
            </div>
          </div>
        </div>
        <!--End-->
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filter" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <vs-table
        v-model="selected"
        pagination
        max-items="20"
        search
        stripe
        :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="numberPart">
          <template v-if="selectedType!=='Serie'">Producto</template>
          <template v-else>Serie</template>
        </vs-th>
        <vs-th>Fecha</vs-th>
        <vs-th sort-key="document">Documento</vs-th>
        <vs-th sort-key="document">Cliente</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="font-medium truncate product-name">
              {{ tr.numberPart + '-' + tr.products.find((p) => p.numberPart === tr.numberPart).description }}</p>
          </vs-td>
          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.numberPart }}</p>
          </vs-td>
          <vs-td>
            <p class="font-medium truncate product-name"> {{ tr.createdAt | date(true) }}</p>
          </vs-td>
          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.code }}</p>
          </vs-td>
          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.client }}</p>
          </vs-td>
          <vs-td>
            <feather-icon @click="openLink(tr.link)" title="Ver documento" icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current" class="mr-2"/>

            <feather-icon @click="showMovements = true, sidebarData = tr"
                          title="Movimientos"
                          icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import Movements from './QuoteMovements'
import openLink from '@/mixins/link'

import { db, Timestamp } from '@/firebase/firebaseConfig'

import { es } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Datepicker,
    Movements
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 15,
      isMounted: false,
      list: [],
      products: [],
      initProgress: false,
      startDate: null,
      endDate: null,
      es: es,
      clients: [],
      types: [
        'Venta',
        'Cotización',
        'Serie'
      ],
      selectedClient: null,
      selectedType: null,
      selectedSKU: null,
      showMovements: false,
      sidebarData: null
    }
  },
  mixins: [openLink],
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadClients()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    /**
     * Filter by date
     */
    async filter () {
      try {
        this.list = []
        if (this.selectedType === 'Venta') {
          this.initProgress = true
          let client = this.selectedClient ? this.selectedClient.id : 'Todos'
          // Dates
          const startDate = this.startDate !== null ? this.startDate : new Date('1971-01-31')
          const endDate = this.endDate !== null ? this.endDate : new Date()
          const starTimes = Timestamp.fromDate(startDate)
          const endTimes = Timestamp.fromDate(endDate)
          // End

          let querySnap = null
          if (this.selectedSKU !== null && this.selectedSKU.length > 0) {
            if (client !== 'Todos') {
              querySnap = await db.collection('sales')
                  .where('filterProducts', 'array-contains', this.selectedSKU)
                  .where('client.id', '==', client)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .get()
            } else {
              querySnap = await db.collection('sales')
                  .where('filterProducts', 'array-contains', this.selectedSKU)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .get()
            }
            querySnap.forEach((doc) => {
              this.list.push({
                id: doc.id,
                type: 'Venta',
                createdAt: doc.data().createdAt.toDate(),
                code: doc.data().code,
                link: doc.data().nubefact.enlace_del_pdf,
                numberPart: this.selectedSKU,
                client: doc.data().client.businessName,
                quote: {
                  ...doc.data().quote,
                  createdAt: doc.data().quote ? doc.data().quote.createdAt.toDate() : null
                }
              })
            })
          } else {
            querySnap = await db.collection('sales')
                .where('client.id', '==', client)
                .where('createdAt', '>=', starTimes)
                .where('createdAt', '<=', endTimes)
                .get()

            querySnap.forEach((doc) => {
              doc.data().products.forEach((p) => {
                this.list.push({
                  id: doc.id,
                  type: 'Venta',
                  createdAt: doc.data().createdAt.toDate(),
                  code: doc.data().code,
                  link: doc.data().nubefact.enlace_del_pdf,
                  numberPart: p.numberPart,
                  client: doc.data().client.businessName,
                  quote: {
                    ...doc.data().quote,
                    createdAt: doc.data().quote ? doc.data().quote.createdAt.toDate() : null
                  },
                  products: doc.data().products
                })
              })
            })
          }
        }
        else if (this.selectedType === 'Cotización') {
          this.initProgress = true
          let client = this.selectedClient ? this.selectedClient.id : 'Todos'
          // Dates
          const startDate = this.startDate !== null ? this.startDate : new Date('1971-01-31')
          const endDate = this.endDate !== null ? this.endDate : new Date()
          const starTimes = Timestamp.fromDate(startDate)
          const endTimes = Timestamp.fromDate(endDate)
          // End
          let querySnap = null

          if (this.selectedSKU !== null && this.selectedSKU.length > 0) {
            if (client !== 'Todos') {
              querySnap = await db.collection('quotes')
                  .where('filterProducts', 'array-contains', this.selectedSKU)
                  .where('client.id', '==', client)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .get()
            } else {
              querySnap = await db.collection('quotes')
                  .where('filterProducts', 'array-contains', this.selectedSKU)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .get()
            }

            querySnap.forEach((doc) => {
              this.list.push({
                id: doc.id,
                type: 'Cotización',
                createdAt: doc.data().createdAt.toDate(),
                code: doc.data().code,
                link: doc.data().link,
                numberPart: this.selectedSKU,
                client: doc.data().client.businessName,
                quote: {
                  code: doc.data().code,
                  id: doc.id,
                  createdAt: doc.data().createdAt.toDate(),
                  link: doc.data().link,
                  currency: doc.data().currency,
                  total: doc.data().total,
                },
                products: doc.data().products
              })
            })

          } else {
            querySnap = await db.collection('quotes')
                .where('client.id', '==', client)
                .where('createdAt', '>=', starTimes)
                .where('createdAt', '<=', endTimes)
                .get()

            querySnap.forEach((quoteDoc) => {
              quoteDoc.data().products.forEach((doc) => {
                this.list.push({
                  id: doc.id,
                  type: 'Cotización',
                  createdAt: quoteDoc.data().createdAt.toDate(),
                  code: quoteDoc.data().code,
                  link: quoteDoc.data().link,
                  numberPart: doc.numberPart,
                  client: quoteDoc.data().client.businessName,
                  quote: {
                    code: quoteDoc.data().code,
                    id: quoteDoc.data().id,
                    createdAt: quoteDoc.data().createdAt.toDate(),
                    link: quoteDoc.data().link,
                    currency: quoteDoc.data().currency,
                    total: quoteDoc.data().total,
                  },
                  products: quoteDoc.data().products
                })
              })
            })
          }
        } else if (this.selectedType === 'Serie' && this.selectedSKU !== null) {
          let querySnap = null
          querySnap = await db.collection('guides')
              .where('serials', 'array-contains', this.selectedSKU)
              .get()
          querySnap.forEach((doc) => {
            this.list.push({
              id: doc.id,
              type: 'Serie',
              createdAt: doc.data().createdAt.toDate(),
              code: doc.data().code,
              link: doc.data().nubefact.enlace_del_pdf,
              numberPart: this.selectedSKU,
              client: doc.data().client.businessName,
              quote: {
                code: doc.data().quote.code,
                id: doc.id,
                createdAt: doc.data().createdAt.toDate(),
                link: doc.data().link,
                currency: doc.data().currency,
                total: doc.data().total,
              }
            })
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async loadClients () {
      this.clients = []
      const querySnap = await db.collection('clients').where('state', '==', true).orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo,
          email: doc.data().email,
          retainer: doc.data().retainer ? doc.data().retainer : 'false'
        }
        this.clients.push(client)
      })
    }
  }
}
</script>

<style lang="scss">
#invoice-list {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
